<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
            <strong>{{ title }}</strong>
            <!-- <a-button class="btn btn-outline-secondary" v-on:click="refreshTable">
              Refresh
            </a-button> -->
            <!-- <a-button
              v-if="mode !== 'netral'"
              ref="undoBtn"
              class="btn btn-outline-secondary ml-2"
              :disabled="input.undoInputdisable"
              v-on:click="undo()"
            >
              {{ input.undoInput }} Undo
            </a-button>
            <a-button
              v-if="mode !== 'netral'"
              ref="redoBtn"
              class="ml-2 btn btn-outline-secondary"
              :disabled="input.redoInputdisable"
              v-on:click="redo()"
            >
              {{ input.redoInput }} Redo
            </a-button> -->
            <a-dropdown>
              <a-button
                class="btn btn-outline-success float-right ml-2"
                style="width: 140px;"
                :loading="importBtnLoading"
                >Import/Template</a-button
              >
              <a-menu slot="overlay">
                <a-menu-item class="text-success" @click="importExcel('normal')">
                  <a-icon type="file-excel" />Import
                </a-menu-item>
                <a-menu-item class="text-success" @click="downloadTemplate">
                  <a-icon type="file-excel" />Template
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <input
              type="file"
              ref="excelFileUpload"
              style="display: none;"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @input="getSheetExcel"
            />
            <a-button
              v-if="mode === 'netral'"
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              :disabled="mode !== 'netral' && mode !== 'oncreate'"
              @click="addNullData"
            >
              Tambah
            </a-button>
            <a-button
              v-if="mode === 'netral'"
              class="btn btn-outline-danger ml-2 mr-2"
              @click="removeOrDelete"
              :disabled="mode !== 'netral'"
            >
              Delete
            </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="saveChanges"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Save Changes
            </a-button>
            <a-button
              class="btn btn-outline-danger ml-2 mr-2"
              @click="cancelaction"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Cancel
            </a-button>
          </div>
          <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
            <!-- <a-dropdown>
              <a-menu slot="overlay">
                <a-menu-item key="1" class="text-success" @click="exportExcel">
                  <a-icon type="file-excel" /> Export Excel
                </a-menu-item>
                <a-menu-item key="2" class="text-warning" @click="previousPeriod">
                  <a-icon type="rollback" /> Ambil dari periode sebelumnya
                </a-menu-item>
                <a-menu-item key="3" class="text-danger" @click="clearData">
                  <a-icon type="delete" /> Clear
                </a-menu-item>
                <a-menu-item key="4" class="text-primary" @click="txtExport">
                  <a-icon type="file-text" /> TxT export
                </a-menu-item>
                <a-menu-item key="5" class="text-dark" @click="paginationruleschange">
                  <a-icon :type="allowPagination ? 'menu-fold' : 'menu-unfold'" /> {{ allowPagination ? 'Disable pagination' : 'Enable pagination' }}
                </a-menu-item>
              </a-menu>
              <a-button
                class="btn btn-outline-dark float-right ml-2"
                style="width: 80px;"
              >
                Menu <a-icon type="down" />
              </a-button>
            </a-dropdown> -->
            <a-input
            v-model="tablequickfilter"
            @input="checkTotal"
            class="pull-right ml-2"
            placeholder="Ketikan nama nasabah?"
            style="width: 200px;"
            />
            <label class="mt-1 pull-right">Cari nasabah</label>
            <!-- <a-select
              v-model="kodeCabang"
              show-search
              class="float-right"
              placeholder="Pilih Kode Cabang"
              option-filter-prop="children"
              style="width: 70px;"
              :filter-option="filterOption"
              @change="filterKodeCabang"
            >
              <a-select-option value="all">
                All
              </a-select-option>
              <a-select-option v-for="(data, index) in dataCabangList" :key="index" :value="data.kode">
                {{ data.kode }}
              </a-select-option>
            </a-select> -->
          </div>
        </div>
          <!-- rowModelType="serverSide" -->
          <!-- serverSideStoreType="partial" -->
          <!-- rowModelType="infinite" -->
        <ag-grid-vue
          style="height: 50vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          overlayLoadingTemplate="<span class='ag-overlay-loading-center'>Data nasabah sedang dimuat.</span>"
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="20"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          @cell-value-changed="onCellValueChanged"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
          :rowClassRules="rowClassRules"
        >
        </ag-grid-vue>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card style="padding: 5px;">
          <a-pagination :key="paginationComponentKey" v-model="pagination.current" :defaultPageSize="pagination.size" :pageSizeOptions="['1000','2000','3000','4000','5000', '10000', '15000', '20000']" :showSizeChanger="true" :total="pagination.totalcount" size="small" :show-total="total => `Total ${total} items`" @showSizeChange="handleChangePagination" @change="handleChangePagination" show-quick-jumper/>
        </a-card>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <ag-grid-vue
          style="height: 23vh;"
          v-if="columnDefsTotal.length !== 0"
          :gridOptions="gridOptionsTotal"
          :rowClassRules="rowClassRules"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :columnDefs="columnDefsTotal"
          :rowData="rowDataTotal"
        ></ag-grid-vue>
      </div> -->
      <a-modal
        title="Preview Import Excel"
        v-model="excelModalVisible"
        :width="800"
        :dialogStyle="{ top: '0px' }"
      >
        <template slot="footer">
          <a-button class="btn btn-outline-dark" @click="excelModalCancel"
            >Cancel</a-button
          >
          <a-button class="btn btn-outline-primary" @click="proccessDataExcel"
            >Process</a-button
          >
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <!-- <a-select
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 250px;"
              v-model="excelModal.selectedKelompok"
              placeholder="Pilih Kelompok"
            >
              <a-select-option
                v-for="(data, index) in dataKelompok"
                :key="index"
                :value="data.id"
                >{{ data.id + ' - ' + data.keterangan }}</a-select-option
              >
            </a-select> -->
            <a-select
              class=""
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 50%;"
              placeholder="Pilih sheet"
              @change="mselectSheet"
            >
              <a-select-option
                v-for="(data, index) in sheetColection"
                :key="index"
                :value="data"
                >{{ data }}</a-select-option
              >
            </a-select>
            <strong class="text-primary ml-2">Data: {{ excelModal.datatable.length }}</strong>
          </div>
          <ag-grid-vue
            style="height: 50vh; width: 100%;"
            :gridOptions="gridOptionsModalExcel"
            :class="
              theme === 'dark'
                ? 'ag-theme-balham-dark mt-2'
                : 'ag-theme-balham mt-2'
            "
            :columnDefs="excelModal.column"
            :rowData="excelModal.datatable"
          ></ag-grid-vue>
        </div>
      </a-modal>
      <a-modal title="TxT Export" v-model="modal.visible">
        <template slot="footer">
          <a-button class="btn btn-outline-success" @click="txtExport('rutin')">Rutin</a-button>
          <a-button class="btn btn-outline-warning" @click="txtExport('koreksi')">Koreksi</a-button>
          <a-button class="btn btn-outline-primary" @click="txtExport('kap')">Koreksi KAP</a-button>
          <a-button class="btn btn-outline-dark" @click="txtExport('pengawas')">Koreksi Pengawas</a-button>
        </template>
        <div class="row">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <label>Periode</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-input v-model="modal.periode" :readOnly="true"></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label>No. Surat</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <a-input v-model="modal.nomor_surat"></a-input>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import numericInput from '../component/numeric'
import selectInput from '../component/select'
import dateInput from '../component/date'
import tooltip from '../component/tooltip'
import htmlrender from '../component/htmlrender'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'

import debounce from 'lodash/debounce'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    excelModalVisible(newValue, oldValue) {
      if (newValue === false) {
        // console.log('oldValue', oldValue)
        this.excelModalCancel()
        // console.log('newValue', newValue)
      } else {
        this.excelModal.column = []
        this.columnDefs.forEach(element => {
          var hname = element.headerName.toString()
          var data = {
            headerName: hname.replaceAll(' ', ''),
            field: hname.replaceAll(' ', ''),
            editable: true,
            resizable: true,
            sortable: true,
          }
          this.excelModal.column.push(data)
        })
      }
    },
  },
  data() {
    return {
      title: 'Nasabah',
      mode: 'netral',
      pagination: {
        current: 1,
        size: 1000,
        totalcount: 0,
      },
      theme: this.$store.state.settings.theme,
      token: null,
      dataCabangList: [],
      modal: {
        visible: false,
        periode: this.$store.state.user.periode,
        nomor_surat: '',
      },
      tablequickfilter: '',
      gridOptions: null,
      gridOptionsTotal: null,
      kodeCabang: 'all',
      allowPagination: localStorage.getItem('gridpagination') || false,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      dataKelompok: [],
      columnDefs: [],
      columnDefsTotal: [],
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      rowData: null,
      rowDataTotal: null,
      changedData: [],
      columnCurrency: [],
      gridOptionsModalExcel: null,
      sheetColection: [],
      importBtnLoading: false,
      excelModalVisible: false,
      excelMode: 'normal',
      excelModal: {
        selectedKelompok: null,
        column: [],
        datatable: [],
      },
      excelErrorExist: false,
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
      rowAfterFilter: [],
      componentKey: 0,
      paginationComponentKey: 0,
      whatform: 'nasabah',
    }
  },
  created() {
    var tokenget = localStorage.getItem(this.whatform + 'inputManyToken')
    if (tokenget !== undefined && tokenget !== null) {
      this.token = tokenget
    }
    this.getAllMaster()
    // console.log('this.allMaster', this.allMaster)
    this.gridOptions = {}
    this.gridOptionsModalExcel = {}
    this.gridOptionsTotal = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.kodeCabang = localStorage.getItem('defaultsandikc') || 'all'
    this.dataCabangList = this.user.cabanglist
    setTimeout(() => {
      var page = localStorage.getItem('ecpPageNasabahPaginationActive')
      if (page) {
        console.log('ecpPageNasabahPaginationActive')
        console.log('page', page)
        this.pagination.current = 1
        this.pagination.size = parseInt(page)
        console.log('this.pagination.current', this.pagination.current)
        console.log('this.pagination.size', this.pagination.size)
        this.paginationComponentKey += 1
        this.getDataPagination(1, page)
      } else {
        console.log('getAllData')
        this.getAllData()
      }
    }, 200)
    // console.log('this.kodeCabang', this.kodeCabang)
    // this.getColumnDefs()
    // this.getAllData()
  },
  methods: {
    moment,
    // New Function
    handleChangePagination(page, pageSize) {
      this.gridApi.showLoadingOverlay()
      // console.log('page', page)
      this.pagination.current = page
      this.pagination.size = pageSize
      // console.log('pageSize', pageSize)
      localStorage.setItem('ecpPageNasabahPaginationActive', pageSize)
      this.getDataPagination(page, pageSize)
    },
    async getDataPagination(
      page = 1,
      perPage = 1000,
    ) {
      this.gridApi.showLoadingOverlay()
      // var backupd = this.$g.clone(this.rowData)
      this.atableloading = true
      var res = await lou.customUrlGet('nasabah?page=' + page + '&perPage=' + perPage + '')
      if (res) {
        this.rowData = res.data
        this.pagination.totalcount = res.count
        this.atableloading = false
        this.gridApi.hideOverlay()
      } else {
        this.pagination.totalcount = 0
        this.atableloading = false
        this.gridApi.hideOverlay()
      }
      this.gridApi.hideOverlay()
    },
    handleTableChange(pagination, filters, sorter) {
      // console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getDataPagination(this.pagination.current, this.pagination.perPage)
      // this.pagination = pager
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // })
    },
    async downloadTemplate() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var worksheet = workbook.addWorksheet('Import Data')
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      var column = this.$g.clone(this.columnDefs)
      var cfirst = 0
      var cloop = 0
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.headerName.toString()
        if (cloop === 0) {
          // console.log('kelompok.id', kelompok.id)
          columnLocation.push({
            location: alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        } else {
          // console.log('kelompok.id', kelompok.id)
          columnLocation.push({
            location: alphabeth[cloopni] + alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        }
        if (cfirst === 25) {
          cfirst = 0
          cloop += 1
        } else {
          cfirst += 1
        }
      }
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Nasabah.xlsx'
      link.click()
    },
    mselectSheet(params) {
      this.selectSheet(params)
    },
    selectSheet(params) {
      // console.log('params', params)
      var that = this
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const ws = wb.Sheets[params]
          // var excellist = []
          /* Convert array of arrays */
          const ret = XLSX.utils.sheet_to_json(ws)
          // console.log('ret', ret)
          // var literalydata = ret.map((x) => {
          //   console.log('x', x[this.excelModal.column[0].field])
          //   if (x[this.excelModal.column[0].field] !== 'undefined') {
          //     return x
          //   }
          // })
          // if (ret.length !== 0) {
          //   // console.log('that.excelModal.datatable', that.excelModal.datatable)
          that.excelModal.datatable = []
          var length = this.excelMode === 'normal' ? ret.length : ret.length - 1
          for (let i = 0; i < length; i++) {
            var element = ret[i]
            if (element[that.excelModal.column[0].field] !== undefined) {
              element.Nama = element.Nama !== null && element.Nama !== undefined ? element.Nama.toString().trim() : null
              element.Saldo = element.Saldo !== null && element.Saldo !== undefined ? parseInt(element.Saldo) : null
              that.excelModal.datatable.push(element)
            }
          }
          // }
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    getSheetExcel() {
      this.importBtnLoading = true
      this.fileupload = this.$refs.excelFileUpload.files[0]
      // console.log('this.fileupload', this.fileupload)
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const wsname = wb.SheetNames
          this.sheetColection = wsname
          this.importBtnLoading = false
          this.excelModalVisible = true
          // console.log('literalydata', literalydata)
          // for (var i = 0; i < ret.length; i++) {
          //   excellist.push(ret[i])
          // }
          // console.log('excellist', ret)
          // const a = wb.Sheets[wb.SheetNames[13]]
          // const headers = this.getHeader(a)
          // console.log('headers', headers)
          // dataexcel = literalydata
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    importExcel(mode) {
      this.excelMode = mode
      this.excelModalFrom = ''
      this.$refs.excelFileUpload.click()
    },
    excelModalCancel() {
      this.excelModalVisible = false
      this.fileupload = null
      this.$refs.excelFileUpload.value = null
    },
    async proccessDataExcel() {
      // var res = null
      var res = {}
      var fd = this.excelModal.datatable
      // console.log('fd', JSON.stringify(fd))
      // console.log('fd', JSON.stringify(fd[0]))
      var newdata = {}
      if (fd.length >= 8000) {
        var loop = 0
        var counter = 0
        fd.forEach((element, index) => {
          counter++
          if (counter === 1) {
            var nobject = {
              ['data' + loop]: { data: [] },
            }
            Object.assign(newdata, nobject)
          }
          newdata['data' + loop].data.push(element)
          if (counter === 8000) {
            counter = 0
            loop++
          }
        })
      }
      if (Object.keys(newdata).length === 0) {
        if (this.mode === 'oncreate') {
          res = await lou.createMasterMany(this.whatform, fd)
        } else {
          res = await lou.updateMasterMany(this.whatform, fd)
        // console.log('fd', fd)
        }
      } else {
        // console.log('newdata', newdata)
        this.failInsertManyData = []
        for (let i = 0; i <= loop; i++) {
          // var time = i + 5
          // console.log(i + '/' + loop, i === loop)
          var resfor = await lou.createMasterMany(this.whatform, newdata['data' + i].data, false)
          if (!resfor) {
            // console.log('There\'s fail data!')
            this.failInsertManyData.push(newdata['data' + i].data)
          }
          if (i === loop) {
            lou.shownotif('Data telah berhasil di kirim!', 'Data telah berhasil di terima di server dan sedang di proses!', 'success')

            if (this.failInsertManyData.length !== 0) {
              lou.shownotif('Terdapat error data!', this.failInsertManyData.length + ' Data gagal dimasukan!', 'error')
              console.log('this.failInsertManyData', this.failInsertManyData)
              // console.log('this.failInsertManyData', JSON.stringify(this.failInsertManyData))
            }
          }
          // console.log("newdata['data' + i].data", newdata['data' + i].data)
        }
      }
      if (res) {
        if (res.token !== undefined && res.token !== null && res.token !== '') {
          this.token = res.token
          localStorage.setItem(this.whatform + 'inputManyToken', res.token)
          this.afterProccessDataExcel(res)
        } else {
          this.mode = 'netral'
          this.changedData = []
          this.afterProccessDataExcel(res)
        }
        // this.getAllData()
      }

      // Cara Lama
      // if (this.excelModal.selectedKelompok === null) {
      //   Modal.confirm({
      //     title: 'Error data',
      //     content: 'Skip error data!?',
      //     okText: 'Agree',
      //     okType: 'primary',
      //     cancelText: 'No',
      //     onOk: async () => {
      //       var fd = this.excelModal.datatable
      //       // console.log('JSON.stringify(fd)', JSON.stringify(fd))
      //       res = await lou.createMaster(this.whatform + '/many', fd)
      //       this.afterProccessDataExcel(res)
      //     },
      //     onCancel: () => {
      //       // console.log('Cancel')
      //       return false
      //     },
      //   })
      // } else {
      // var res = null
      // var fd = this.excelModal.datatable
      // console.log('JSON.stringify(fd)', JSON.stringify(fd))
      // res = await lou.createMaster(this.whatform + '/many', fd)
      // if (res.token !== undefined && res.token !== null) {
      //   this.token = res.token
      //   localStorage.setItem(this.whatform + 'inputManyToken', res.token)
      //   lou.shownotif('Info', res.message)
      //   this.afterProccessDataExcel(res)
      // } else {
      //   lou.shownotif('Success', res.message)
      //   this.afterProccessDataExcel(res)
      // }
      // }
    },
    afterProccessDataExcel(res) {
      if (res !== null) {
        if (res) {
          this.excelModalVisible = false
          this.fileupload = null
          this.$refs.excelFileUpload.value = null
          this.getAllData()
        }
      }
    },
    previousPeriod() {
      Modal.confirm({
        title: 'Timpa?',
        content: 'Apakah anda ingin menimpa data saat ini!',
        okText: 'Yes',
        okType: 'success',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlGet(this.whatform + '/copyprevious?force=true')
          if (res) {
            // lou.shownotif('Success', this.whatform.replaceAll('_', ' ').toUpperCase() + ' see previous period!', 'success')
            this.getAllData()
          }
        },
        onCancel: async () => {
          var res = await lou.customUrlGet(this.whatform + '/copyprevious?force=false')
          if (res) {
            // lou.shownotif('Success', this.whatform.replaceAll('_', ' ').toUpperCase() + ' see previous period!', 'success')
            this.getAllData()
          }
        },
      })
    },
    // handleMenuClick(e) {
    //   console.log('click', e)
    // },
    paginationruleschange() {
      this.allowPagination = !this.allowPagination
      localStorage.setItem('gridpagination', this.allowPagination)
      // location.reload()
      this.componentKey += 1
    },
    checkTotal: debounce(async function () {
      // console.log('this.tablequickfilter', this.tablequickfilter)
      if (this.tablequickfilter === '') {
        // this.rowAfterFilter = this.$g.clone(this.rowData)

        var page = localStorage.getItem('ecpPageNasabahPaginationActive')
        // console.log('page', page)
        if (page) {
          this.pagination.current = 1
          this.pagination.size = parseInt(page)
          this.getDataPagination(1, page)
        } else {
          this.getAllData()
        }
      } else {
        var res = await lou.customUrlGet('nasabah/nama/' + this.tablequickfilter)
        if (res) {
          this.rowData = res.data
        }
        // var data = []
        // this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
        //   data.push(rowNode.data)
        // })
        // this.rowAfterFilter = this.$g.clone(data)
      }
      // console.log('data', data)
      // this.hitungTotal()
    }, 800),
    async exportExcel() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columnDefs)
      var data = this.$g.clone(this.rowAfterFilter)
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.headerName.toString()
        if (cloop === 0) {
          columnLocation.push({
            location: alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        } else {
          columnLocation.push({
            location: alphabeth[cloopni] + alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        }
        for (let u = 0; u < data.length; u++) {
          const el = data[u]
          if (cloop === 0) {
            worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.field]
          } else {
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.field]
          }
          var checklast = u + 1
          if (checklast === data.length) {
            dincrement = 2
          } else {
            dincrement += 1
          }
        }
        if (cfirst === 25) {
          cfirst = 0
          cloop += 1
        } else {
          cfirst += 1
        }
      }
      var last = data.length + 3
      // console.log('last', last)
      worksheet.mergeCells('A' + last + ':B' + last)
      worksheet.getCell('A' + last).value = 'Total'
      for (let ti = 0; ti < this.columnDefsTotal.length; ti++) {
        const element = this.columnDefsTotal[ti]
        var targetLoc = columnLocation.findIndex(x => x.field === element.field)
        if (targetLoc >= 0) {
          worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.field]
        }
        // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.field])
      }
      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      var form = this.whatform
      link.download = 'Export Excel Form ' + form + '.xlsx'
      link.click()
    },
    // previousPeriod() {
    //   var periode = this.$store.state.user.periode
    //   console.log('before', periode)
    //   var after = moment(periode, 'YYYY-MM-DD').subtract(1, 'months').endOf('months').format('YYYY-MM-DD')
    //   console.log('after', after)
    // },
    clearData() {
      Modal.confirm({
        title: 'Apakah anda yakin?',
        content: 'Data tidak dapat dikembalikan!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlDelete(this.whatform + '/clear')
          if (res) {
            this.getAllData()
            // this.rowData = []
            // this.backupRowData = []
            // this.rowDataTotal = []
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    txtExport(e) {
      var dataperiode = this.$g.clone(this.backupRowData)
      // var user = this.$store.state.user
      // var form = this.whatform.slice(7, this.whatform.length)
      // var periode = moment(user.periode, 'YYYY-MM-DD').endOf('months').format('YYYY-MM-DD')
      // var status = e === 'rutin' ? '0' : e === 'koreksi' ? '1' : e === 'kap' ? '2' : e === 'pengawas' ? '3' : '0'
      // var nomorsurat = status === '2' ? this.modal.nomor_surat : ''
      // var content = 'H01|' + user.kodesektor + '|' + user.sandibpr + '|' + periode + '|LBBPRS|KC' + form + '|' + status + '|' + nomorsurat + '\r\n'
      var content = ''
      for (let u = 0; u < dataperiode.length; u++) {
        const el = dataperiode[u]
        for (let i = 0; i < this.columnDefs.length; i++) {
          const element = this.columnDefs[i]
          var lastKah = i + 1
          var dq = el[element.field] === null ? '' : el[element.field]
          if (element.cellEditor === 'dateEditor') {
            if (el[element.field] !== null) {
              dq = el[element.field].toString()
              // dq = ndq.replaceAll('-', '')
            }
          }
          var imbuhan = lastKah === this.columnDefs.length ? '' : '|'
          content += dq.toString() + imbuhan
          if (lastKah === this.columnDefs.length) {
            content += '\r\n'
          }
        }
      }
      // any kind of extension (.txt,.cpp,.cs,.bat)

      var blob = new Blob([content], {
        type: 'text/plain;charset=utf-8',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      // var RorK = e === 'rutin' ? 'R' : 'K'
      // var posisiperiode = moment(user.periode, 'YYYY-MM-DD').endOf('months').format('YYYYMMDD')
      link.download = 'text export.txt'
      link.click()
    },
    showModal() {
      this.modal.visible = true
    },

    hitungTotal() {
      var dataTotal = []
      var groupbysandi = this.rowAfterFilter.reduce(function (r, a) {
        if (a.sandikc !== undefined && a.sandikc !== null) {
          r[a.sandikc] = r[a.sandikc] || []
          r[a.sandikc].push(a)
        }
        return r
      }, Object.create(null))
      var sandikc = Object.keys(groupbysandi)
      // console.log('groupbysandi', groupbysandi)
      // console.log('sandikc', sandikc)
      var columncurrency = this.columnCurrency
      for (let index = 0; index < sandikc.length; index++) {
        const keyobject = sandikc[index]
        // console.log('columncurrency', columncurrency)
        // console.log('keyobject', keyobject)
        var datap = {
          // from: keyobject,
        }
        for (let i = 0; i < columncurrency.length; i++) {
          if (i === 0) datap.from = keyobject
          const el = columncurrency[i]
          // eslint-disable-next-line no-redeclare
          var total = 0
          for (let u = 0; u < groupbysandi[keyobject].length; u++) {
            const element = groupbysandi[keyobject][u]
            total += element[el.field]
          }
          datap[el.field] = total
          datap.jumlahdata = groupbysandi[keyobject].length
        }
        // console.log('datap', datap)
        if (Object.keys(datap).length !== 0) {
          dataTotal.push(datap)
        }
      }
      var dataptotal = {}
      for (let i = 0; i < columncurrency.length; i++) {
        if (i === 0) dataptotal.from = 'Total'
        const el = columncurrency[i]
        // eslint-disable-next-line no-redeclare
        var total = 0
        for (let u = 0; u < this.rowAfterFilter.length; u++) {
          const element = this.rowAfterFilter[u]
          total += element[el.field]
        }
        dataptotal[el.field] = total
        dataptotal.jumlahdata = this.rowAfterFilter.length
      }
      dataptotal.isPrimary = 1
      dataptotal.isBold = true
      // dataptotal.jumlahdata =
      // console.log('dataptotal', dataptotal)
      if (Object.keys(dataptotal).length !== 0) {
        dataTotal.push(dataptotal)
      }
      // console.log('this.columnCurrency', this.columnCurrency)
      this.columnDefsTotal = this.$g.clone(this.columnCurrency).map((cv, index) => {
        var ret = cv
        cv.valueFormatter = this.currencyFormatter
        cv.type = 'rightAligned'
        // console.log('ret', ret)
        return ret
      })
      if (this.columnCurrency.length !== 0) {
        this.columnDefsTotal.splice(0, 0, {
          headerName: 'From',
          field: 'from',
          width: '70',
          sortable: true,
        })
      }
      this.columnDefsTotal.push({
        headerName: 'Jumlah data',
        field: 'jumlahdata',
        width: '70',
        sortable: true,
      })
      // console.log('this.columnDefsTotal', this.columnDefsTotal)
      this.rowDataTotal = this.$g.clone(dataTotal)
      // console.log('dataTotal', dataTotal)
      // console.log('this.rowDataTotal', this.rowDataTotal)
      // console.log('dataTotal', dataTotal)
    },
    filterKodeCabang() {
      if (this.kodeCabang === 'all') {
        this.rowAfterFilter = this.$g.clone(this.backupRowData)
      } else {
        var newdata = this.backupRowData.filter(
          (x) => x.sandikc === this.kodeCabang,
        )
        this.rowAfterFilter = this.$g.clone(newdata)
      }
      this.rowData = this.rowAfterFilter
      this.hitungTotal()
    },
    async getAllMaster() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(x => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id)
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet('master', false, true)
        var master = {
          mykey: 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(x => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id)
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      this.getColumnDefs()
      // var page = localStorage.getItem('ecpPageNasabahPaginationActive')
      // console.log('page', page)
      // if (page) {
      //   this.pagination.current = 1
      //   this.pagination.size = parseInt(page)
      //   this.getDataPagination(1, page)
      // } else {
      //   this.getAllData()
      // }
    },
    async getColumnDefs() {
      var nindexeddb = await lou.getDataFromDb()
      // console.log('nindexxeddb', nindexeddb)
      var gcIndexTarget = nindexeddb.findIndex(x => x.mykey === 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var column = []
      var res = null
      // console.log('res.data', res.data)
      if (gcIndexTarget < 0) {
        res = await lou.customUrlGet('master/tables', false, true)
        // console.log('res.data', res.data)
        column = res.data[this.whatform]
        var master = {
          mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        // console.log('column', column)
      } else {
        var dataFDB = JSON.parse(nindexeddb[gcIndexTarget].data)
        // console.log('dataFDB', dataFDB)
        if (dataFDB[this.whatform] !== undefined) {
          column = dataFDB[this.whatform]
        } else {
          await lou.deleteDataFromDb(nindexeddb[gcIndexTarget].id)
          res = await lou.customUrlGet('master/tables', false, true)
          column = res.data[this.whatform]
          // console.log('column', column)
          var masters = {
            mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
            data: JSON.stringify(res.data),
          }
          await lou.addDataToDb(masters)
        }
        // console.log('column', column)
      }
      this.columnDefs = []
      this.contohdata = {}
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var data = element
        if (data.isHide === 'false') {
          if (data.valueFormatter === '') {
            delete data.valueFormatter
          } else {
            if (data.valueFormatter === 'currency') {
              this.columnCurrency.push({
                field: data.field.toLowerCase(),
                headerName: data.headerName,
                valueFormatter: this.currencyFormatter,
                resizable: true,
              })
              data.valueFormatter = this.currencyFormatter
            }
          }
          if (data.reftable !== undefined && data.reftable !== '' && data.reftable !== null) {
            var object = this.allMaster[data.reftable]
            if (object !== undefined) {
              data.cellEditor = 'selectEditor'
              data.cellEditorParams = {
                values: object,
                reffield: data.retreffield,
              }
            }
          }
          if (data.cellEditor === '') delete data.cellEditor
          if (data.pinned === '') delete data.pinned
          data.editable = data.editable === 'true'
          if (data.filter === 'true') {
            data.filter = true
          } else if (data.filter === 'false') {
            data.filter = false
          } else if (data.filter === 'agDateColumnFilter') {
            data.filterParams = {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                var dateAsString = cellValue
                if (dateAsString == null) return -1
                var dateParts = dateAsString.split('-')
                var cellDate = new Date(
                  Number(dateParts[0]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[2]),
                )
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1
                }
              },
            }
          }
          data.sortable = data.sortable === 'true'
          data.resizable = data.resizable === 'true'
          data.field = data.field.toLowerCase()
          // data.cellClassRules = data.rules
          data.tooltipField = data.field
          data.tooltipComponent = 'customTooltip'
          this.contohdata[data.field] = ''
          this.columnDefs.push(data)
        }
      }
      this.refreshTable()
    },
    async getAllData(showloading = false) {
      if (showloading) {
        lou.shownotif('Loading...', 'Data sedang di refresh.')
      }
      var res = null
      // console.log('this.token', this.token)
      // console.log('this.whatform', this.whatform)
      if (this.token !== null) {
        var that = this
        this.intervalGet = setInterval(async () => {
          res = await lou.readMaster(that.whatform + '?token=' + this.token, false)
          if (res) {
            that.token = null
            localStorage.removeItem(that.whatform + 'inputManyToken')
            clearInterval(that.intervalGet)
            that.stopingInterval('intervalGet')
            that.makeItHappend(res)
            lou.shownotif('Nasabah', 'Antrian data telah selesai ditambahkan!', 'success')
          }
        }, 7000)
      } else {
        res = await lou.readMaster(this.whatform)
        this.makeItHappend(res)
        if (res) {
          if (showloading) {
            lou.shownotif('Success', 'Data telah berhasil di refresh.', 'success')
          }
        }
      }
    },
    makeItHappend(res) {
      // console.log('this.token', this.token)
      if (res) {
        this.rowData = res.data
        this.pagination.totalcount = res.count
        this.backupRowData = this.$g.clone(this.rowData)
        this.rowAfterFilter = this.$g.clone(this.rowData)
        this.hitungTotal()
      } else {
        this.rowData = []
        this.rowDataTotal = []
      }
    },
    stopingInterval(intervalName) {
      // console.log('intervalName', intervalName)
      clearInterval(this[intervalName])
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    checkData() {
      // this.
    },
    async saveChanges() {
      var fd = this.changedData.length !== 0 ? this.$g.clone(this.changedData) : []
      // var user = this.$store.state.user
      if (fd.length === 0) lou.shownotif('Belum ada perubahan')
      if (this.mode === 'oncreate') {
        fd.forEach((element) => {
          delete element.id
          delete element.periode
          if (element.newdata) {
            delete element.newdata
          }
        })
      } else if (this.mode === 'onupdate') {
        fd.forEach((element) => {
          element.id = parseInt(element.id)
          delete element.periode
        })
      }
      // fd[0].tglmulai = fd[0].tglmulai.slice(0, 10)
      // fd[0].tgljatuhtempo = fd[0].tgljatuhtempo.slice(0, 10)
      // fd[0].tglpenilaianterakhir = fd[0].tglpenilaianterakhir.slice(0, 10)
      var res = {}
      // console.log('fd', JSON.stringify(fd))
      // console.log('fd', JSON.stringify(fd[0]))
      var newdata = {}
      if (fd.length >= 1000) {
        var loop = 0
        var counter = 0
        fd.forEach((element, index) => {
          counter++
          if (counter === 1) {
            var nobject = {
              ['data' + loop]: { data: [] },
            }
            Object.assign(newdata, nobject)
          }
          newdata['data' + loop].data.push(element)
          if (counter === 1000) {
            counter = 0
            loop++
          }
        })
      }
      if (Object.keys(newdata).length === 0) {
        if (this.mode === 'oncreate') {
          res = await lou.createMasterMany(this.whatform, fd)
        } else {
          res = await lou.updateMasterMany(this.whatform, fd)
        // console.log('fd', fd)
        }
      } else {
        // console.log('newdata', newdata)
        this.failInsertManyData = []
        for (let i = 0; i <= loop; i++) {
          // var time = i + 5
          console.log(i + '/' + loop, i === loop)
          var resfor = await lou.createMasterMany(this.whatform, newdata['data' + i].data, false)
          if (!resfor) {
            // console.log('There\'s fail data!')
            this.failInsertManyData.push(newdata['data' + i].data)
          }
          if (i === loop) {
            lou.shownotif('Data telah berhasil di kirim!', 'Data telah berhasil di terima di server dan sedang di proses!', 'success')

            if (this.failInsertManyData.length !== 0) {
              lou.shownotif('Terdapat error data!', this.failInsertManyData.length + ' Data gagal dimasukan!', 'error')
              console.log('this.failInsertManyData', this.failInsertManyData)
              // console.log('this.failInsertManyData', JSON.stringify(this.failInsertManyData))
            }
          }
          // console.log("newdata['data' + i].data", newdata['data' + i].data)
        }
      }
      if (res) {
        if (res.token !== undefined && res.token !== null && res.token !== '') {
          this.token = res.token
          localStorage.setItem(this.whatform + 'inputManyToken', res.token)
          this.afterProccessDataExcel(res)
        } else {
          this.mode = 'netral'
          this.changedData = []
          this.afterProccessDataExcel(res)
        }
        // this.getAllData()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl = element.cellEditor === 'dateEditor'
        if (element.field === 'flagdetail' || element.field === 'FlagDetail' || element.field === 'Flagdetail') {
          fd[element.field] = 'D01'
        } else if (booltgl) {
          fd[element.field] = null
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = null
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.rowData.splice(0, 0, fd)
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.rowData.filter((x) => x.newdata === undefined)
        // console.log('maped', maped)
        this.rowData = maped
      } else {
        // var changedRowNode = []
        this.rowData = this.$g.clone(this.backupRowData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.refreshTable()
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.deleteMaster(this.whatform, id)
          var page = localStorage.getItem('ecpPageNasabahPaginationActive')
          console.log('page', page)
          if (page) {
            this.pagination.current = 1
            this.pagination.size = parseInt(page)
            this.getDataPagination(1, page)
          } else {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    refreshTable() {
      var params = {
        force: true,
        suppressFlash: false,
      }

      this.gridOptions.api.refreshCells(params)
      // console.log('kerefresh kok')
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    undo() {
      this.gridApi.undoCellEditing()
    },
    redo() {
      this.gridApi.redoCellEditing()
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    titleFormat(word) {
      return lou.capitalizeTheFirstLetterOfEachWord(word)
    },
  },
}
</script>

<style></style>
